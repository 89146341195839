import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import assetViewDetails from '../../assetViewDetails'


export default {
  name: 'logicalAssets',
  components: {
    appStrings,
    commonHelper,
    assetViewDetails
  },
  props: ['currentLocation','locAccessFlag','rowData'],
  watch: {
    currentPage: function() {
    //   this.getAuditHistory();
    this.logicalData()

    },
    perPage: function() {
      this.currentPage = 1;
    //   this.getAuditHistory();
    this.logicalData()

    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      responseMsg: '',
      isSuccess: false,
      logicalAssetsData: [],
      logicalAssetsFields: [
        {
          key: 'doc_num',
          label:'Asset Number'
        },
        {
          key: 'serial_no',
          label: 'Serial Number',
        },
        {
          key: 'po_number',
          label: 'PO Number',
        },
        {
          key: 'description',
          label: 'Item Description',
        },
        {
          key: 'creation_date',
        },
        {
          key: 'created_by',
        },
        {
            key:'asset_details',
          },
      ],
      accessButtons:{},
      showValueSetModal:false,
      assetNum:null,
      showHideAssetDetailsModalFlag:false,
      payload:{}
    };
  },
  validations: {},
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          //   this.showHideAddFaLocationModal(true);
        }
      }
    });
    this.logicalData()
  },
  methods: {
    logicalData(){
        if(this.rowData.locator_level=='L1'){
        this.payload = {
            _page: this.currentPage - 1,
            _limit: this.perPage,
            rack_id: this.rowData.locator_id,
            type:'rack'
          };
        }
        else if(this.rowData.locator_level=='L2'){
            this.payload = {
                _page: this.currentPage - 1,
                _limit: this.perPage,
                shelf_id: this.rowData.locator_id,
                type:'shelf'
              };
            }
            else{
                this.payload = {
                    _page: this.currentPage - 1,
                    _limit: this.perPage,
                    bin_id: this.rowData.locator_id,
                    type:'bin'
                  };
                }
                this.loader = true;
            this.$store
            .dispatch(`assets/getLogicalAssets`, this.payload)
            .then(resp => {
              this.loader = false;
              if (resp.status === 200) {
                this.logicalAssetsData=resp.data.data.page;
                this.totalRows=resp.data.data.total_elements;
              }
            })
            .catch(() => {
              this.loader = false;
            });
         
    },
    showHideAssetDetailsModal(flag,asstNum){
        this.assetNum=asstNum
        this.showHideAssetDetailsModalFlag=flag
        if(flag && !this.locAccessFlag) {
          this.accessButtons = {
            deleteAllow: false,
            downloadAllow: false,
            uploadAllow: false,
            editAllow: false,
            addAllow: false,
            printAllow: false,
            approvalAllow: false
          };
        }
      },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
