import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
// import { format } from 'date-fns';
import defaultData from './defaultData';
import viewLogicalAssets from './viewLogicalAssets';

export default {
  name: 'addEditAudit',
  components: {
    appStrings,
    DatePicker,
    commonHelper,
    defaultData,
    viewLogicalAssets
  },
  props: ['faLocation','currentLocation', 'locId', 'faId','locAccessFlag'],
  data() {
    return {
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      payload: null,
      detailsAssetAuditData: [],
      detailsAssetAuditFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1 wid'
        },
        {
          key: 'shelf_details'
        },
        {
          key: 'locator_name',
          label: 'Rack Name',
          class: 'textDeco'
        },
        {
          key: 'start_date',
          class: 'textDeco'
        },
        {
          key: 'end_date',
          class: 'textDeco'
        },
        {
          key: 'assign_to',
          class: 'textDeco'
        },
        // {
        //   key: 'scanned_asset',
        //   class: 'textDeco'
        // },
        {
          key: 'logical_asset',
          class: 'textDeco'
        }
      ],
      shelfList: [],
      shelfFields: [
        {
          key: 'selectBox1',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1 wid'
        },
        {
          key: 'bin_details'
        },
        {
          key: 'locator_name',
          label: 'Shelf Name',
          class: 'textDeco'
        },
        {
          key: 'start_date',
          class: 'textDeco'
        },
        {
          key: 'end_date',
          class: 'textDeco'
        },
        {
          key: 'assign_to',
          class: 'textDeco'
        },
        {
          key: 'logical_asset',
          class: 'textDeco'
        }
      ],
      binList: [],
      binFields: [
        {
          key: 'selectBox2',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1 wid'
        },
        {
          key: 'locator_name',
          label: 'Bin Name',
          class: 'textDeco'
        },
        {
          key: 'start_date',
          class: 'textDeco'
        },
        {
          key: 'end_date',
          class: 'textDeco'
        },
        {
          key: 'assign_to',
          class: 'textDeco'
        },
        {
          key: 'logical_asset',
          class: 'textDeco'
        }
      ],
      selectAllCheckBox: false,
      selectAllCheckBox1: false,
      selectAllCheckBox2: false,
      selectedRow: [],
      showHideDefaultDataModalFlag: false,
      checkFlag: false,
      editMode: false,
      faLocation: {
        text: null,
        value: null
      },
      showValueSetModal: false,
      selectFlag: false,
      count:null,
      count1:null,
      dateCount:null,
      rackDate:null,
      shelfDate:null,
      binDate:null,
      locatorId:null,
      accessButtons:{},
      rowData:null,
      viewLogicalAssetsModalFlag:false
    };
  },
  validations: {},
  mounted() {
    this.getAddEditAudit();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          //   this.showHideAddFaLocationModal(true);
        }
        if (actionName === 'save' && !this.showHideDefaultDataModalFlag) {
          // if(this.selectedRow.length>0){
            this.saveAddEditAuditData()
          // }else{
          //   alert('Select a row first')
          // }
        }
      }
    });
  },
  methods: {
    getAddEditAudit() {
      const payload = {
        // _page: this.currentPage - 1,
        // _limit: this.perPage,
        locId: Number(this.faId)
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAddEditAuditData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            if(resp.data.data.length>0){
              for (let i = 0; i < this.detailsAssetAuditData.length; i++) {
                this.detailsAssetAuditData[i].selectBox = false;
              }
              for (let i = 0; i < this.shelfList.length; i++) {
                this.shelfList[i].selectBox1 = false;
              }
              for (let i = 0; i < this.binList.length; i++) {
                this.binList[i].selectBox2 = false;
              }
              this.detailsAssetAuditData = resp.data.data.filter(
                ele => ele.locator_level === 'L1'
              );
              this.shelfList = resp.data.data.filter(
                ele => ele.locator_level === 'L2'
              );
              this.binList = resp.data.data.filter(
                ele => ele.locator_level === 'L3'
              );
              this.totalRows = resp.data.data.total_elements;
            }else{
              this.$bvToast.toast("All the Racks, Shelves and Bins are already under Audit process", {
                title: 'Alert',
                toaster:'b-toaster-top-center',
                variant: 'danger',
                solid: true,
                noFade:true
              });
            }

          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getShelfDetails(shelfDtl, shelfData) {
      shelfDtl.shelfList = this.shelfList.filter(
        ele => ele.parent_locator_id == shelfDtl.locator_id
      );
      if (this.selectFlag) {
        // shelfDtl.shelfList.map(ele => {
        //   ele.selectBox1 = this.selectFlag;
        //   ele.start_date = shelfDtl.start_date;
        //   ele.end_date = shelfDtl.end_date;
        //   ele.disFlag = true;
        // });
        this.shelfList.map(ele=>{
          if(ele.parent_locator_id==shelfDtl.locator_id){
            ele.selectBox1 = this.selectFlag;
          ele.start_date = shelfDtl.start_date;
          ele.end_date = shelfDtl.end_date;
          ele.assign_to=shelfDtl.assign_to;
          ele.disFlag = true;
          }
          return ele
        })
        if (shelfDtl.selectBox == false) {
          // shelfDtl.shelfList.map(ele => {
          //   ele.selectBox1 = false;
          //   ele.disFlag = false;
          // });
          this.shelfList.map(ele=>{
            if(shelfDtl.locator_id==ele.parent_locator_id){
              ele.selectBox1 = false;
              ele.disFlag = false;
            }
          })
        }
      } else if (!this.selectFlag && shelfDtl.selectBox == true) {
        // shelfDtl.shelfList.map(ele => {
        //   ele.selectBox1 = true;
        //   ele.start_date = shelfDtl.start_date;
        //   ele.end_date = shelfDtl.end_date;
        //   ele.disFlag = true;
        // });
        this.shelfList.map(ele => {
          if(shelfDtl.locator_id==ele.parent_locator_id){
            ele.selectBox1 = true;
            ele.start_date = shelfDtl.start_date;
            ele.end_date = shelfDtl.end_date;
            ele.assign_to=shelfDtl.assign_to;
            ele.disFlag = true;
          }
        });
      } else {
        shelfDtl.shelfList.map(ele => {
          if(shelfDtl.locator_id==ele.parent_locator_id){
          ele.selectBox1 = this.selectFlag;
          ele.disFlag = false;
          }
        });
        // this.shelfList.map(ele => {
        //   if(ele.parent_locator_id==shelfDtl.locator_id){}
        //   ele.selectBox1 = this.selectFlag;
        //   ele.disFlag = false;
        // });
        
      }
      shelfDtl.shelfList = this.shelfList.filter(
        ele => ele.parent_locator_id == shelfDtl.locator_id
      )
      shelfData.toggleDetails();
    },
    getBinDetails(binDtl, binData) {
      binDtl.binList = this.binList.filter(
        ele => ele.parent_locator_id == binDtl.locator_id
      );
      if (this.selectFlag) {
        // binDtl.binList.map(ele => {
        //   ele.selectBox2 = this.selectFlag;
        //   ele.start_date = binDtl.start_date;
        //   ele.end_date = binDtl.end_date;
        //   ele.disFlag = true;
        // });
        this.binList.map(ele=>{
          if(ele.parent_locator_id==binDtl.locator_id){
            ele.selectBox2 = this.selectFlag;
            ele.start_date = binDtl.start_date;
            ele.end_date = binDtl.end_date;
            ele.assign_to=binDtl.assign_to;
            ele.disFlag = true;
          }
        });
        if (binDtl.selectBox1 == false) {
          // binDtl.binList.map(ele => {
          //   ele.selectBox2 = false;
          //   ele.disFlag = false;
          // });
          this.binList.map(ele=>{
            if(binDtl.locator_id==ele.parent_locator_id){
              ele.selectBox2 = false;
              ele.disFlag = false;
            }
          })
        }
      } else if (!this.selectFlag && binDtl.selectBox1 == true) {
        // binDtl.binList.map(ele => {
        //   ele.selectBox2 = true;
        //   ele.start_date = binDtl.start_date;
        //   ele.end_date = binDtl.end_date;
        //   ele.disFlag = true;
        // });
        this.binList.map(ele => {
          if(binDtl.locator_id==ele.parent_locator_id){
            ele.selectBox2 = true;
            ele.start_date = binDtl.start_date;
            ele.end_date = binDtl.end_date;
            ele.assign_to=binDtl.assign_to;
            ele.disFlag = true;
          }
        });
      } else {
        // binDtl.binList.map(ele => {
        //   ele.selectBox2 = this.selectFlag;
        //   ele.disFlag = false;
        // });
        this.binList.map(ele => {
          if(binDtl.locator_id==ele.parent_locator_id){
          ele.selectBox2 = this.selectFlag;
          ele.disFlag = false;
          }
        });
        
      }
      binDtl.binList = this.binList.filter(
        ele => ele.parent_locator_id == binDtl.locator_id
      );
      binData.toggleDetails();
    },
    getPreviousDay(date = new Date()) {
      const previous = new Date(date.getTime());
      previous.setDate(date.getDate() - 1);

      return previous;
    },
    selectAllBoxChecked(flag, data) {
      this.selectedRow = [];
      if (flag && data.field.key == 'selectBox') {
        this.selectFlag = true;
        this.detailsAssetAuditData = this.detailsAssetAuditData.map(data => {
          data.selectBox = this.selectAllCheckBox;
          if (data.shelfList) {
            data.shelfList.map(data => {
              data.selectBox1 = this.selectAllCheckBox;
              if (data.binList) {
                data.binList.map(data => {
                  data.selectBox2 = this.selectAllCheckBox;
                });
              }
            });
          }
          return data;
        });
        this.detailsAssetAuditData.map(ele => {
          this.selectedRow.push(ele);
        });
        this.shelfList.map(ele=>{
          ele.selectBox1=flag
        })
        this.binList.map(ele=>{
          ele.selectBox2=flag
        })
      }
      else if (!flag && data.field.key == 'selectBox') {
        this.selectFlag = false;
        this.detailsAssetAuditData = this.detailsAssetAuditData.map(data => {
          data.selectBox = this.selectAllCheckBox;
          if (data.shelfList) {
            data.shelfList.map(data => {
              data.selectBox1 = this.selectAllCheckBox;
              if (data.binList) {
                data.binList.map(data => {
                  data.selectBox2 = this.selectAllCheckBox;
                });
              }
            });
          }
          return data;
        });
        this.shelfList.map(ele=>{
          ele.selectBox1=flag
        })
        this.binList.map(ele=>{
          ele.selectBox2=flag
        })
        this.selectedRow = [];
      }
    },
    selectBoxChecked(flag, index, item) {
      if (!this.selectFlag) {
        if (item.shelfList) {
          item.shelfList = item.shelfList.map(data => {
            data.selectBox1 = flag;
            data.disFlag = flag;
            if (data.binList) {
              data.binList.map(data => {
                data.selectBox2 = flag;
                data.disFlag = flag;
              });
            }
            else{
              this.binList.map(datas=>{
                if(data.locator_id==datas.parent_locator_id){
                  datas.selectBox2 = flag;
                datas.disFlag = flag;
                }
              })
            }
            return data;
          });
        }
        this.shelfList.map(ele=>{
          if(ele.parent_locator_id==item.locator_id){
            ele.selectBox1=flag;
            ele.disFlag=flag;
            this.binList.map(eles=>{
              if(eles.parent_locator_id==ele.locator_id){
                eles.selectBox2=ele.selectBox1;
              eles.disFlag=ele.disFlag
              }
            })
          }
        })
        setTimeout(() => {
          this.detailsAssetAuditData = JSON.parse(
            JSON.stringify(this.detailsAssetAuditData)
          );
        }, 0);
        if (flag) {
          this.selectedRow.push(item);
        } else {
          for (let i = 0; i < this.selectedRow.length; i++) {
            if (item.locator_id == this.selectedRow[i].locator_id) {
              this.selectedRow.splice(i, 1);
            }
          }
        }
      } else {
        if (item.shelfList) {
          item.shelfList = item.shelfList.map(data => {
            data.selectBox1 = flag;
            data.disFlag = flag;
            if (data.binList) {
              data.binList.map(data => {
                data.selectBox2 = flag;
                data.disFlag = flag;
              });
            }
            return data;
          });
        }
        this.shelfList.map(ele=>{
          if(item.locator_id==ele.parent_locator_id){
            ele.selectBox1=flag;
            ele.disFlag=flag;
          }
          if(ele.selectBox1==true){
            this.binList.map(items=>{
              if(ele.locator_id==items.parent_locator_id){
                items.selectBox2=ele.selectBox1,
                items.disFlag=flag=ele.disFlag
              }
            })
          }
        })
        setTimeout(() => {
          this.detailsAssetAuditData = JSON.parse(
            JSON.stringify(this.detailsAssetAuditData)
          );
        }, 0);
        // if (flag) {
        //   this.selectedRow.push(item);
        // } else {
        //   for (let i = 0; i < this.selectedRow.length; i++) {
        //     if (item.locator_id == this.selectedRow[i].locator_id) {
        //       this.selectedRow.splice(i, 1);
        //     }
        //   }
        // }

      }
    },
    selectBoxChecked1(flag, index, item) {
      if (!this.selectFlag) {
        if (item.binList) {
          item.binList = item.binList.map(data => {
              data.selectBox2 = flag;
              data.disFlag = flag;
            return data;
          });
        }
        this.binList.map(ele=>{
          if(ele.parent_locator_id==item.locator_id){
            ele.selectBox2 = flag;
            ele.disFlag = flag;
          }
        })
        // this.detailsAssetAuditData.map(ele=>{
        //   if(ele.locator_id==item.parent_locator_id){
        //     ele.selectBox=flag
        //   }
        // })
        // setTimeout(() => {
        //   this.detailsAssetAuditData = JSON.parse(
        //     JSON.stringify(this.detailsAssetAuditData)
        //   );
        // }, 0);
        
        if (flag) {
            for (let i = 0; i < this.detailsAssetAuditData.length; i++) {
              if (this.detailsAssetAuditData[i].locator_id == item.parent_locator_id) {
                if(this.selectedRow.length==0){
                this.selectedRow.push(this.detailsAssetAuditData[i]);
                }else{
                  let count=0
                  for(let j=0;j<this.selectedRow.length;j++){
                    if(this.selectedRow[j].locator_id==item.parent_locator_id){
                      this.selectedRow[j]=this.detailsAssetAuditData[i]
                      count++
                    }
                  }
                  if(count==0){
                    this.selectedRow.push(this.detailsAssetAuditData[i])
                  }
                }
              }
            }
        this.shelfList.map(ele=>{
          if(ele.locator_id==item.locator_id){
            ele.selectBox1=item.selectBox1
            ele.disFlag=item.disFlag
          }
        })

        this.detailsAssetAuditData.map(ele=>{
          if(ele.locator_id==item.parent_locator_id){
            ele.selectBox=flag
          }
        })
        setTimeout(() => {
          this.detailsAssetAuditData = JSON.parse(
            JSON.stringify(this.detailsAssetAuditData)
          );
        }, 0);
        } else {
          this.selectedRow.map(ele=>{
            if(ele.shelfList){
              ele.shelfList.map(items=>{
                if(items.locator_id==item.locator_id){
                  items.selectBox1=flag
                  if(items.binList){
                    items.binList.map(eles=>{
                      eles.selectBox2=flag
                    })
                  }
                }
              })
            }
      
          })
          for (let i = 0; i < this.selectedRow.length; i++) {
            if(this.selectedRow[i].shelfList){
              let shelfCnt=0;
              let shelfCntFlag=0
              for(let j=0;j<this.selectedRow[i].shelfList.length;j++){
                if(!this.selectedRow[i].shelfList[j].selectBox1){
                  shelfCntFlag++
                }
                shelfCnt++
              }
              if(shelfCntFlag==shelfCnt){
                this.selectedRow.splice(i, 1)
                this.detailsAssetAuditData.map(ele=>{
                  if(ele.locator_id==item.parent_locator_id){
                    ele.selectBox=flag
                  }
                })
                setTimeout(() => {
                  this.detailsAssetAuditData = JSON.parse(
                    JSON.stringify(this.detailsAssetAuditData)
                  );
                }, 0);
              }
            }
          }
        }

      } 
    },
    selectBoxChecked2(flag, index, item) {
      this.binList.map(ele=>{
        if(ele.locator_id==item.locator_id){
          ele.selectBox2=item.selectBox2
        }
      })
      this.shelfList.map(ele=>{
        if(ele.locator_id==item.parent_locator_id){
          ele.selectBox1=flag
        }
      })
      // this.detailsAssetAuditData.map(ele=>{
      //   if(ele.locator_id==item.parent_locator_id){
      //     ele.selectBox=flag
      //   }
      // })
      setTimeout(() => {
        this.shelfList = JSON.parse(
          JSON.stringify(this.shelfList)
        );
      }, 0);
      setTimeout(() => {
        this.detailsAssetAuditData = JSON.parse(
          JSON.stringify(this.detailsAssetAuditData)
        );
      }, 0);
      if (flag) {
        this.detailsAssetAuditData.map(items=>{
        if(items.shelfList){
          items.shelfList.map(ele=>{
            if(ele.locator_id==item.parent_locator_id){
              ele.selectBox1=flag;
              if(this.selectedRow.length==0){
              this.selectedRow.push(items);
              }else{
                let count=0
                for(let j=0;j<this.selectedRow.length;j++){
                  if(this.selectedRow[j].locator_id==ele.parent_locator_id){
                    this.selectedRow[j]=items
                    count++
                  }
                }
                if(count==0){
                  this.selectedRow.push(items)
                }
              }

              if(ele.selectBox1==flag){
                items.selectBox=flag
              }
            }
          })
        }
        })
        this.selectedRow.map(ele=>{
          if(ele.shelfList){
            ele.shelfList.map(eles=>{
              if(eles.binList){
                eles.binList.map(item=>{
                  if(item.parent_locator_id==eles.locator_id && item.selectBox2==true){
                    eles.binFlag=true;
                  }
                })
              }
            })
          }
        })
      } else{
        this.detailsAssetAuditData.map(items=>{
          if(items.shelfList){
            items.shelfList.map(ele=>{
              // if(ele.locator_id==item.parent_locator_id){
              //   ele.selectBox1=flag;
              // }
              // if(ele.selectBox1==flag){
              //   items.selectBox=flag
              // }
              let count=0;
              if(ele.binList){
                ele.binList.map(el=>{
                  if(el.selectBox2){
                    count++
                  }
                })
              }
              if(count==0){
                 if(ele.locator_id==item.parent_locator_id){
                 ele.selectBox1=flag;
                 this.locatorId=ele.parent_locator_id
              }
              }
            })
          }
          if(this.locatorId==items.locator_id){
            items.selectBox=flag;
          }
          this.locatorId=null
          })

          

      }
    },

    showHideDefaultDataModal(flag) {
      this.showHideDefaultDataModalFlag = flag;
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },

    populateData(startDate, endDate, assigned) {
      this.rackDate=startDate
      this.selectedRow.map(item => {
        item.start_date = item.selectBox ? startDate : null;
        item.end_date = item.selectBox ? endDate : null;
        item.assign_to = item.selectBox ? assigned : null;
        if (item.shelfList) {
          this.shelfDate=startDate
          item.shelfList.map(item => {
            item.start_date = item.selectBox1 ? startDate : null;
            item.end_date = item.selectBox1 ? endDate : null;
            item.assign_to = item.selectBox1 ? assigned : null;
            if (item.binList) {
              this.binDate=startDate
              item.binList.map(item => {
                item.start_date = item.selectBox2 ? startDate : null;
                item.end_date = item.selectBox2 ? endDate : null;
                item.assign_to = item.selectBox2 ? assigned : null;
              });
            }
          });
        } 
        
          this.shelfList.map(ele=>{
            if(ele.parent_locator_id==item.locator_id){
              ele.start_date = ele.selectBox1 ? startDate : null;
              ele.end_date = ele.selectBox1 ? endDate : null;
              ele.assign_to = ele.selectBox1 ? assigned : null;
                this.binList.map(item=>{
                  if(item.parent_locator_id==ele.locator_id && item.selectBox2){
                    item.start_date = item.selectBox2 ? startDate : null;
                    item.end_date = item.selectBox2 ? endDate : null;
                    item.assign_to = item.selectBox2 ? assigned : null;
                  }

                })
            }
       
          })
      });
      setTimeout(() => {
        this.selectedRow = JSON.parse(JSON.stringify(this.selectedRow));
      }, 0);
      for (let i = 0; i < this.detailsAssetAuditData.length; i++) {
        for (let j = 0; j < this.selectedRow.length; j++) {
          if (
            this.detailsAssetAuditData[i].locator_id ==
            this.selectedRow[j].locator_id
          ) {
            this.detailsAssetAuditData[i] = this.selectedRow[j];
          }
        }
      }
        setTimeout(() => {
          this.detailsAssetAuditData = JSON.parse(
            JSON.stringify(this.detailsAssetAuditData)
          );
        }, 0);
      this.showHideDefaultDataModal(false);
    },
    saveAddEditAuditData() {
      const payload = {
        audit_hdr_id: 0,
        end_date: null,
        location_id: this.faId,
        start_date: null,
        audit_status:'Initiated',
        audit_dtl: [
        {  
          audit_dtl_id: 0,
          audit_hdr_id: 0,
          scanned_status: 0,
          scanning_complete_flag: 0,
          status: null,
          rack_dtl:[]
          }
        ]
      };
      this.selectedRow.map(item => {
        payload.audit_dtl[0].rack_dtl.push({
              rack_id:item.locator_id,
              shelf_dtl:[]
           
        });
      });
      payload.audit_dtl[0].rack_dtl.map(ele=>{
          for(let j=0;j<this.shelfList.length;j++){
          if((this.shelfList[j].selectBox1 || this.shelfList[j].binFlag) && ele.rack_id==this.shelfList[j].parent_locator_id){
            ele.shelf_dtl.push({
              shelf_id:this.shelfList[j].locator_id,
              bin_dtl:[]
            })
          }
        }
        ele.shelf_dtl.map(ele=>{
          for(let i=0;i<this.binList.length;i++){
            if(this.binList[i].selectBox2 && ele.shelf_id==this.binList[i].parent_locator_id){
              ele.bin_dtl.push({
                bin_id:this.binList[i].locator_id,
                start_date:this.binList[i].start_date,
                end_date:this.binList[i].end_date,
                assign_to:this.binList[i].assign_to,
              })
            }
          }
        })
      })
     
      if(payload.audit_dtl[0].rack_dtl.length!==0){
        payload.audit_dtl[0].rack_dtl.map(ele=>{
        if(ele.shelf_dtl.length!==0){
          ele.shelf_dtl.map(item=>{
            if(item.bin_dtl.length!==0){
              item.bin_dtl.map(el=>{
                if(el.start_date==(null||undefined)||el.end_date==(null||undefined)||el.assign_to==(null||undefined)){
                  this.dateCount++
                }
              })
            }else{
              this.dateCount++
            }
          })
        }else{
          this.dateCount++
        }
      })
    }else{
      this.dateCount++
    }
      if(this.dateCount){
        alert("Start Date or End Date or Assign To can not be null")
        this.dateCount=null
      }
      else{
        this.loader = true;
      this.$store
        .dispatch('assets/postAddEditAudit', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.showAlert = true;
            (this.isSuccess = true);
            (this.responseMsg = resp.data.message);
            this.editMode = false;
            this.totalRows = resp.data.data.total_elements;
            // this.getAddEditAudit();
            // this.selectedRow=[];
            this.$emit('showHideAssetAuditModal', false);
          }
          else{
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.specIndex = index;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        (this.faLocation.text = item.value_code),
          (this.faLocation.value = item.value_set_dtl_id);
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      this.specIndex = index;
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        (this.faLocation.text = null), (this.faLocation.value = null);
      }
    },
startDate(data) {
this.selectedRow.map(item=>{
    this.shelfList.map(ele=>{
      if(data.locator_id==ele.parent_locator_id && item.selectBox==true && item.locator_id==data.locator_id){
        ele.start_date=data.start_date
        ele.end_date=null
      }
        this.binList.map(items=>{
          if(ele.locator_id==items.parent_locator_id && ele.selectBox1==true){
            items.start_date=data.start_date
            items.end_date=null
          }
        })
    })
})
this.detailsAssetAuditData.map(ele=>{
  ele.end_date=null
  if(ele.shelfList){
    ele.shelfList.map(item=>{
      if(data.locator_id==item.parent_locator_id){
        item.start_date=data.start_date
        item.end_date=null
      
      if(item.binList){
        item.binList.map(items=>{
          if(item.locator_id==items.parent_locator_id){
            items.start_date=data.start_date
            items.end_date=null
          }
        })
      }
    }
    })
  }
})
setTimeout(() => {
  this.detailsAssetAuditData = JSON.parse(
    JSON.stringify(this.detailsAssetAuditData)
  );
}, 0);

},
changeRackDate(data){
  this.rackDate=data.start_date
},
disabledRackDates(date){
  return commonHelper.disabledDates(date, this.rackDate);
},
assignTo(data){
  this.selectedRow.map(item=>{
    this.shelfList.map(ele=>{
      if(data.locator_id==ele.parent_locator_id && item.selectBox==true && item.locator_id==data.locator_id){
        ele.assign_to=data.assign_to
      }
        this.binList.map(items=>{
          if(ele.locator_id==items.parent_locator_id && ele.selectBox1==true){
            items.assign_to=data.assign_to
          }
        })
    })
})
this.detailsAssetAuditData.map(ele=>{
  if(ele.shelfList){
    ele.shelfList.map(item=>{
      if(data.locator_id==item.parent_locator_id){
        item.assign_to=data.assign_to
      
      if(item.binList){
        item.binList.map(items=>{
          if(item.locator_id==items.parent_locator_id){
            items.assign_to=data.assign_to
          }
        })
      }
    }
    })
  }
})
setTimeout(() => {
  this.detailsAssetAuditData = JSON.parse(
    JSON.stringify(this.detailsAssetAuditData)
  );
}, 0);
},
    startDate1(data){
        this.shelfList.map(ele=>{
          if(ele.selectBox1==true){
            this.binList.map(item=>{
              if(data.locator_id==item.parent_locator_id){
                item.start_date=data.start_date
                item.end_date=null
              }
            })
          }
        })
        this.detailsAssetAuditData.map(ele=>{
          if(ele.shelfList){
            ele.shelfList.map(item=>{
              item.end_date=null
                if(item.binList){
                  item.binList.map(items=>{
                    if(data.locator_id==items.parent_locator_id){
                      items.start_date=data.start_date
                      items.end_date=null
                    }
                  })
                
              }
            })
          }
        })
        setTimeout(() => {
          this.detailsAssetAuditData = JSON.parse(
            JSON.stringify(this.detailsAssetAuditData)
          );
        }, 0);
    },
    assignTo1(data){
      this.shelfList.map(ele=>{
        if(ele.selectBox1==true){
          this.binList.map(item=>{
            if(data.locator_id==item.parent_locator_id){
              item.assign_to=data.assign_to
            }
          })
        }
      })
      this.detailsAssetAuditData.map(ele=>{
        if(ele.shelfList){
          ele.shelfList.map(item=>{
              if(item.binList){
                item.binList.map(items=>{
                  if(data.locator_id==items.parent_locator_id){
                    items.assign_to=data.assign_to
                  }
                })
              
            }
          })
        }
      })
      setTimeout(() => {
        this.detailsAssetAuditData = JSON.parse(
          JSON.stringify(this.detailsAssetAuditData)
        );
      }, 0);
    },
    changeShelfDate(data){
      this.shelfDate=data.start_date
    },
    disabledShelfDates(date){
      return commonHelper.disabledDates(date, this.shelfDate);
    },
    startDate2(data){
      this.binList.map(item=>{
        if(data.locator_id==item.locator_id){
          item.start_date=data.start_date
          item.end_date=null
        }
      })
    },   
    assignTo2(data){
      this.binList.map(item=>{
        if(data.locator_id==item.locator_id){
          item.assign_to=data.assign_to
        }
      })
    }, 
    changeBinDate(data){
      this.binDate=data.start_date
    },
    disabledBinDates(date){
      return commonHelper.disabledDates(date, this.binDate);
    },
    endDate(data) {
      if(data.start_date==null){
        this.$bvToast.toast("Select Start Date first", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        data.end_date=null
      }
      else{this.selectedRow.map(item=>{
        this.shelfList.map(ele=>{
          if(data.locator_id==ele.parent_locator_id && item.selectBox==true && item.locator_id==data.locator_id){
            ele.end_date=data.end_date
          }
            this.binList.map(items=>{
              if(ele.locator_id==items.parent_locator_id && ele.selectBox1==true){
                items.end_date=data.end_date
              }
            })
          
        })
      })
      this.detailsAssetAuditData.map(ele=>{
        if(ele.shelfList){
          ele.shelfList.map(item=>{
            if(data.locator_id==item.parent_locator_id){
              item.end_date=data.end_date
            if(item.binList){
              item.binList.map(items=>{
                if(item.locator_id==items.parent_locator_id){
                  items.end_date=data.end_date
                }
              })
            }
          }
          })
        }
      })
      setTimeout(() => {
        this.detailsAssetAuditData = JSON.parse(
          JSON.stringify(this.detailsAssetAuditData)
        );
      }, 0);    
    }
      
    },
    endDate1(data){
      if(data.start_date==null){
        this.$bvToast.toast("Select Start Date first", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        data.end_date=null
      }
      else{
        this.shelfList.map(ele=>{
          if(ele.selectBox1==true){
            this.binList.map(item=>{
              if(data.locator_id==item.parent_locator_id){
                item.end_date=data.end_date
              }
            })
          }
        })
        this.detailsAssetAuditData.map(ele=>{
          if(ele.shelfList){
            ele.shelfList.map(item=>{
              if(item.binList){
                item.binList.map(items=>{
                  if(data.locator_id==items.parent_locator_id){
                    items.end_date=data.end_date
                  }
                })
              
            }
            })
          }
        })
        setTimeout(() => {
          this.detailsAssetAuditData = JSON.parse(
            JSON.stringify(this.detailsAssetAuditData)
          );
        }, 0);
      }
    },
    endDate2(data){
      if(data.start_date==null){
        this.$bvToast.toast("Select Start Date first", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        data.end_date=null
      }else{
        this.binList.map(item=>{
          if(data.locator_id==item.locator_id){
            item.end_date=data.end_date
          }
        })
      }
    },
    viewLogicalAssetsModal(flag,item){
      this.viewLogicalAssetsModalFlag=flag
      this.rowData=item;
    }
    // disableEndDate(date){
      // return commonHelper.disabledDates(date, date);
    // }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
